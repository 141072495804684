// Each step has its own identifier
export enum StepId {
  LOCATION = 'location',
  INFORMATION = 'information',
  DOCUMENTS = 'documents',
  WALLBOXES = 'wallboxes',
  CONSULTATION = 'consultation',
  CONTACT_FORM = 'contactForm',
  SUMMARY = 'summary',
}

// You can optionally divide steps into groups,
// each one having its own identifier
export enum StepCategoryId {}

export enum StepGroupId {}

import {
  GcProductWallboxDataExtension,
  GcSwProduct,
  chargingPowersWatt,
} from '@/lib/greenportal/load-products';
import { AssistantContext } from '~/steps/steps';
import { getNumberOfSimilarAttributes } from '~/helper/compare-wallboxes';

/**
 * Filter all available wallboxes by context
 */
export const filteredWallboxes = (
  context: AssistantContext,
  wallboxes: GcSwProduct[],
): GcSwProduct[] => {
  const kwArray: chargingPowersWatt[] = [];
  if (
    context.location.threeKW !== '–' &&
    Number(context.location.threeKW) > 0
  ) {
    kwArray.push(3700);
  }
  if (
    context.location.elevenKW !== '–' &&
    Number(context.location.elevenKW) > 0
  ) {
    kwArray.push(11000);
  }
  if (
    context.location.twentyKW !== '–' &&
    Number(context.location.twentyKW) > 0
  ) {
    kwArray.push(22000);
  }
  if (
    context.location.fiftyKW !== '–' &&
    Number(context.location.fiftyKW) > 0
  ) {
    kwArray.push(50000);
  }
  const contextWallboxData: GcProductWallboxDataExtension = {
    outdoorReady: context.location.lisArea.includes('outdoorArea'),
    chargingPowersWatt: kwArray,
    billable: context.information.settleUser.includes('yes'),
  };
  return [...wallboxes].filter(
    // If there is at least one property matching the context return this wallbox
    (wallbox) => getNumberOfSimilarAttributes(contextWallboxData, wallbox) >= 1,
  );
};

import component from './LocationForm.vue';
import { ValidationType } from '@/consts/validation-types';
import { AssistantStepContext } from '@/interfaces/context';
import {
  FormCheckboxValueType,
  FormMultipleChoiceValueType,
  FormTextFieldValueType,
  StepDefinition,
} from '@/interfaces/step-definition';
import {
  buildFormCheckbox,
  buildFormMultipleChoiceField,
  buildFormTextField,
} from '@/lib/forms/builder';
import i18n from '@/plugins/i18n';
import { StepId } from '~/consts/assistant-steps';

export interface LocationStepContext extends AssistantStepContext {
  street: FormTextFieldValueType;
  streetNumber: FormTextFieldValueType;
  zipcode: FormTextFieldValueType;
  city: FormTextFieldValueType;
  lisArea: FormMultipleChoiceValueType;
  customLisArea: FormTextFieldValueType;
  alreadyHasLis: FormCheckboxValueType;
  threeKW: FormTextFieldValueType;
  elevenKW: FormTextFieldValueType;
  twentyKW: FormTextFieldValueType;
  fiftyKW: FormTextFieldValueType;
}

// is set to true after the first time the dealBreaker is called
let dealBreakerCalled = false;
const config: StepDefinition = {
  beforeNext: (context) => {
    if (!context.location.lisArea.includes('other')) {
      context.location.customLisArea = '';
    }
    return true;
  },
  component,
  fields: [
    buildFormTextField('street', {
      required: true,
    }),
    buildFormTextField('streetNumber', {
      required: true,
    }),
    buildFormTextField('zipcode', {
      required: true,
      validation: [ValidationType.postalCodeDE],
    }),
    buildFormTextField('city', {
      required: true,
    }),
    buildFormMultipleChoiceField('lisArea', {
      component: {
        options: [
          {
            value: 'undergroundCarPark',
          },
          {
            value: 'outdoorArea',
          },
          {
            value: 'both',
          },
          {
            value: 'other',
          },
        ],
        columns: 2,
        singleAnswer: true,
      },
      required: true,
      outputFormatter: (data) => ({
        formattedLabel: i18n.t(
          'location.formFields.lisArea.formattedLabel',
        ) as string,
        formattedValue: data.formattedValue,
      }),
    }),
    buildFormTextField('customLisArea', {
      isVisible: (context) => context.location.lisArea.includes('other'),
      outputFormatter: (data) => ({
        formattedLabel:
          data?.formattedValue !== '–' ? data.formattedLabel : false,
        formattedValue:
          data?.formattedValue !== '–' ? data.formattedValue : false,
      }),
    }),
    buildFormCheckbox('alreadyHasLis', {
      component: {
        showLabel: true,
      },
      default: false,
      hideLabel: true,
      outputFormatter: (data) => ({
        formattedLabel: i18n.t(
          'location.formFields.alreadyHasLis.formattedLabel',
        ) as string,
        formattedValue:
          data.formattedValue !== '–'
            ? (i18n.t('location.formFields.alreadyHasLis.yes') as string)
            : (i18n.t('location.formFields.alreadyHasLis.no') as string),
      }),
    }),
    buildFormTextField('threeKW', {
      component: {
        min: 0,
        type: 'number',
      },
      outputFormatter: (data) => ({
        formattedLabel:
          data.formattedValue !== '–' && data.formattedValue !== '0'
            ? (i18n.t('location.formFields.threeKW.formattedLabel') as string)
            : false,
        formattedValue:
          data.formattedValue !== '–' && data.formattedValue !== '0'
            ? data.formattedValue
            : false,
      }),
    }),
    buildFormTextField('elevenKW', {
      component: {
        min: 0,
        type: 'number',
      },
      outputFormatter: (data) => ({
        formattedLabel:
          data.formattedValue !== '–' && data.formattedValue !== '0'
            ? (i18n.t('location.formFields.elevenKW.formattedLabel') as string)
            : false,
        formattedValue:
          data.formattedValue !== '–' && data.formattedValue !== '0'
            ? data.formattedValue
            : false,
      }),
    }),
    buildFormTextField('twentyKW', {
      component: {
        min: 0,
        type: 'number',
      },
      outputFormatter: (data) => ({
        formattedLabel:
          data.formattedValue !== '–' && data.formattedValue !== '0'
            ? (i18n.t('location.formFields.twentyKW.formattedLabel') as string)
            : false,
        formattedValue:
          data.formattedValue !== '–' && data.formattedValue !== '0'
            ? data.formattedValue
            : false,
      }),
    }),
    buildFormTextField('fiftyKW', {
      component: {
        min: 0,
        type: 'number',
      },
      outputFormatter: (data) => ({
        formattedLabel:
          data.formattedValue !== '–' && data.formattedValue !== '0'
            ? (i18n.t('location.formFields.fiftyKW.formattedLabel') as string)
            : false,
        formattedValue:
          data.formattedValue !== '–' && data.formattedValue !== '0'
            ? data.formattedValue
            : false,
      }),
    }),
  ],
  isDealBreaker: (context) => {
    // once the view is mounted the isDealBreaker(context) is called before the user does anything. don't show the dealBreakerDialog in this case
    if (!dealBreakerCalled) {
      dealBreakerCalled = true;
      return false;
    }
    if (
      Number(context.location.threeKW) > 0 ||
      Number(context.location.elevenKW) > 0 ||
      Number(context.location.twentyKW) > 0 ||
      Number(context.location.fiftyKW) > 0
    ) {
      return false;
    }
    return {
      text: i18n.t('location.dealBreaker.text'),
    };
  },
  id: StepId.LOCATION,
};

export default config;

import {
  GcProductWallboxDataExtension,
  GcSwProduct,
} from '@/lib/greenportal/load-products';

// Returns the number of similar properties from AssistantContext to a wallbox
export const getNumberOfSimilarAttributes = (
  contextWallbox: GcProductWallboxDataExtension,
  wallbox: GcSwProduct,
) => {
  let similarCount = 0;
  if (
    wallbox.extensions.gcProductWallboxData === undefined ||
    !wallbox.extensions
  ) {
    return 0;
  }
  const wallboxData = wallbox.extensions.gcProductWallboxData[0];

  // Compare outdoorReady attribute with context
  if (contextWallbox.outdoorReady === wallboxData.outdoorReady) {
    similarCount++;
  }
  // Compare chargingPowersWatt attribute with context
  if (
    contextWallbox.chargingPowersWatt?.some((p) =>
      wallboxData.chargingPowersWatt?.includes(p),
    )
  ) {
    similarCount++;
  }

  // Compare billable attribute with context
  if (contextWallbox.billable === wallboxData.billable) {
    similarCount++;
  }
  return similarCount;
};


















































































import { PropType, defineComponent, ref } from '@vue/composition-api';
import {
  faCheck,
  faInfoCircle,
  faPlus,
  faUndo,
} from '@fortawesome/pro-solid-svg-icons';
import GcImage from '@/components/primitives/GcImage.vue';
import GcRow from '@/components/primitives/GcRow.vue';
import GcCol from '@/components/primitives/GcCol.vue';
import GcCard from '@/components/primitives/GcCard.vue';
import GcButton from '@/components/primitives/GcButton.vue';
import { formatPrice } from '@/lib/formatter/price';
import GcTextField from '@/components/primitives/GcTextField.vue';
import GcSlider from '@/components/primitives/GcSlider.vue';
import GcCombobox from '@/components/primitives/GcCombobox.vue';
import GcAlert from '@/components/primitives/GcAlert.vue';
import { filteredWallboxes } from '~/data/filter-wallboxes';
import {
  GcSwProduct,
  loadProductsByIds,
} from '@/lib/greenportal/load-products';
import WallboxModal from '~/components/wallbox-modal/WallboxModal.vue';
import { useWallboxModal } from '~/components/wallbox-modal/wallbox-modal';
import { AssistantContext } from '~/steps/steps';
import { ids } from '~/data/wallboxes-to-load';

export default defineComponent({
  components: {
    WallboxModal,
    GcAlert,
    GcButton,
    GcCard,
    GcCol,
    GcCombobox,
    GcImage,
    GcRow,
    GcSlider,
    GcTextField,
  },
  props: {
    assistantContext: {
      required: true,
      type: Object as PropType<AssistantContext>,
    },
  },
  setup: (props) => {
    const { openWallboxModal } = useWallboxModal();
    const loading = ref<boolean>(true);
    const loadingError = ref<null | boolean>(null);

    const wallboxes = ref<GcSwProduct[]>([]);

    const baseUrl = process.env.VUE_APP_BASE_URL;

    loadProductsByIds(ids)
      .then((success) => {
        wallboxes.value = filteredWallboxes(props.assistantContext, success);
      })
      .catch((error) => {
        // eslint-disable-next-line
        console.log(error);
        loadingError.value = true;
      })
      .finally(() => (loading.value = false));
    const productDescription = (wallbox) =>
      wallbox.extensions?.gcProductData[0]?.translated?.extDescription;

    const productUrl = (wallbox) =>
      wallbox.cover?.media?.url
        ? wallbox.cover?.media?.url
        : `${baseUrl}/image-placeholder.svg`;

    return {
      baseUrl,
      faCheck,
      faInfoCircle,
      faPlus,
      faUndo,
      formatPrice,
      openWallboxModal,
      productDescription,
      productUrl,
      loading,
      loadingError,
      wallboxes,
    };
  },
});






































import { defineComponent } from '@vue/composition-api';
import GcImage from '@/components/primitives/GcImage.vue';
import { headerIcon } from '~/plugins/font-awesome';

export default defineComponent({
  components: {
    GcImage,
  },
  props: {
    started: {
      default: false,
      type: Boolean,
    },
  },
  setup: () => {
    const showHeaderBackground: boolean =
      process.env.VUE_APP_SHOW_HEADER_BACKGROUND !== 'false';
    const baseUrl = process.env.VUE_APP_BASE_URL;
    return {
      baseUrl,
      headerIcon,
      showHeaderBackground,
    };
  },
});

import {
  faGaugeMax,
  faNetworkWired,
  faWifi,
} from '@fortawesome/pro-duotone-svg-icons';
import { StepId } from '~/consts/assistant-steps';
import {
  FormMultipleChoiceValueType,
  FormTextFieldValueType,
  StepDefinition,
} from '@/interfaces/step-definition';
import {
  buildFormMultipleChoiceField,
  buildFormTextField,
} from '@/lib/forms/builder';
import { AssistantStepContext } from '@/interfaces/context';
import i18n from '@/plugins/i18n';

export interface InformationStepContext extends AssistantStepContext {
  settleUser: FormMultipleChoiceValueType;
  powerKW: FormTextFieldValueType;
  internetConnection: FormMultipleChoiceValueType;
  propertyOwner: FormMultipleChoiceValueType;
  branchOfIndustry: FormMultipleChoiceValueType;
  customBranchOfIndustry: FormTextFieldValueType;
}

const config: StepDefinition = {
  fields: [
    buildFormMultipleChoiceField('settleUser', {
      component: {
        options: [
          {
            value: 'yes',
          },
          {
            value: 'no',
          },
        ],
        columns: 2,
        singleAnswer: true,
      },
      required: false,
      outputFormatter: (data) => ({
        formattedLabel: i18n.t(
          'information.formFields.settleUser.formattedLabel',
        ) as string,
        formattedValue: data.formattedValue,
      }),
    }),
    buildFormTextField('powerKW', {
      component: {
        suffix: 'kW',
        min: 0,
        type: 'number',
      },
      showHelperDialog: true,
      required: false,
      outputFormatter: (data) => {
        if (data.formattedValue === '0' || data.formattedValue === '–') {
          return {
            formattedLabel: false,
          };
        }
        return {
          formattedLabel: i18n.t(
            'information.formFields.powerKW.formattedLabel',
          ) as string,
          formattedValue: data?.formattedValue + ' kW',
        };
      },
    }),
    buildFormMultipleChoiceField('internetConnection', {
      component: {
        options: [
          {
            icon: faNetworkWired,
            value: 'lan',
          },
          {
            icon: faWifi,
            value: 'wlan',
          },
          {
            icon: faGaugeMax,
            value: 'lte',
          },
        ],
        columns: 3,
        singleAnswer: false,
        verticalOptionLayout: true,
      },
      showHelperDialog: true,
      required: false,
      outputFormatter: (data) => ({
        formattedLabel: i18n.t(
          'information.formFields.internetConnection.formattedLabel',
        ) as string,
        formattedValue: data.formattedValue,
      }),
    }),
    buildFormMultipleChoiceField('propertyOwner', {
      component: {
        options: [
          {
            value: 'yes',
          },
          {
            value: 'no',
          },
        ],
        columns: 2,
        singleAnswer: true,
      },
      required: true,
      outputFormatter: (data) => ({
        formattedLabel: i18n.t(
          'information.formFields.propertyOwner.formattedLabel',
        ) as string,
        formattedValue: data.formattedValue,
      }),
    }),
  ],
  id: StepId.INFORMATION,
};

export default config;

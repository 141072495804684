import component from './LocationForm.vue';
import { AssistantStepContext } from '@/interfaces/context';
import {
  FormCheckboxValueType,
  FormMultipleChoiceValueType,
  FormTextFieldValueType,
  StepDefinition,
} from '@/interfaces/step-definition';
import {
  buildFormCheckbox,
  buildFormMultipleChoiceField,
  buildFormTextField,
} from '@/lib/forms/builder';
import i18n from '@/plugins/i18n';
import { StepId } from '~/consts/assistant-steps';

export interface LocationStepContext extends AssistantStepContext {
  street: FormTextFieldValueType;
  streetNumber: FormTextFieldValueType;
  zipcode: FormTextFieldValueType;
  city: FormTextFieldValueType;
  lisArea: FormMultipleChoiceValueType;
  customLisArea: FormTextFieldValueType;
  alreadyHasLis: FormCheckboxValueType;
  chargingPoints: FormTextFieldValueType;
}

const config: StepDefinition = {
  beforeNext: (context) => {
    if (!context.location.lisArea.includes('other')) {
      context.location.customLisArea = '';
    }
    return true;
  },
  component,
  fields: [
    buildFormMultipleChoiceField('lisArea', {
      component: {
        options: [
          {
            value: 'undergroundCarPark',
          },
          {
            value: 'outdoorArea',
          },
          {
            value: 'both',
          },
          {
            value: 'other',
          },
        ],
        columns: 2,
        singleAnswer: true,
      },
      required: true,
      outputFormatter: (data) => ({
        formattedLabel: i18n.t(
          'location.formFields.lisArea.formattedLabel',
        ) as string,
        formattedValue: data.formattedValue,
      }),
    }),
    buildFormTextField('customLisArea', {
      isVisible: (context) => context.location.lisArea.includes('other'),
      outputFormatter: (data) => ({
        formattedLabel:
          data?.formattedValue !== '–' ? data.formattedLabel : false,
        formattedValue:
          data?.formattedValue !== '–' ? data.formattedValue : false,
      }),
    }),
    buildFormCheckbox('alreadyHasLis', {
      component: {
        showLabel: true,
      },
      default: false,
      hideLabel: true,
      outputFormatter: (data) => ({
        formattedLabel: i18n.t(
          'location.formFields.alreadyHasLis.formattedLabel',
        ) as string,
        formattedValue:
          data.formattedValue !== '–'
            ? (i18n.t('location.formFields.alreadyHasLis.yes') as string)
            : (i18n.t('location.formFields.alreadyHasLis.no') as string),
      }),
    }),
    buildFormTextField('chargingPoints', {
      component: {
        min: 0,
        type: 'number',
      },
      showHelperDialog: true,
      required: false,
      outputFormatter: (data) => {
        if (data.formattedValue === '0' || data.formattedValue === '–') {
          return {
            formattedLabel: false,
          };
        }
        return {
          formattedLabel: i18n.t(
            'location.formFields.chargingPoints.formattedLabel',
          ) as string,
          formattedValue: data?.formattedValue,
        };
      },
    }),
  ],
  id: StepId.LOCATION,
};

export default config;

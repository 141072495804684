import component from './DocumentsForm.vue';
import { StepId } from '~/consts/assistant-steps';
import {
  FormTextAreaValueType,
  FormUploadFieldValueType,
  StepDefinition,
} from '@/interfaces/step-definition';
import { AssistantStepContext } from '@/interfaces/context';
import {
  buildFormFileUploadField,
  buildFormTextArea,
} from '@/lib/forms/builder';

export interface DocumentsStepContext extends AssistantStepContext {
  uploadDocuments: FormUploadFieldValueType;
  additionalComments: FormTextAreaValueType;
}

const config: StepDefinition = {
  component,
  fields: [
    buildFormFileUploadField('uploadDocuments', {
      showDescription: true,
      showHelperDialog: true,
      component: {
        multiple: true,
      },
    }),
    buildFormTextArea('additionalComments', {
      required: false,
      showHelperDialog: true,
    }),
  ],

  id: StepId.DOCUMENTS,
};

export default config;

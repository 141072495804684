



































import { PropType, computed, ref } from '@vue/composition-api';
import { VDatePicker, VInput } from 'vuetify/lib';
import {
  formatDate,
  formatDateRange,
  parseDate,
  parseDates,
} from '@/lib/formatter/dates';
import GcMenu from '@/components/primitives/GcMenu.vue';
import GcTextField from '@/components/primitives/GcTextField.vue';
import { AssistantContext } from '~/steps/steps';

export default VInput.extend({
  components: {
    GcMenu,
    GcTextField,
    VDatePicker,
  },
  props: {
    assistantContext: {
      default: () => ({}),
      type: Object as PropType<AssistantContext>,
    },
    max: {
      type: [String, Function],
    },
    min: {
      type: [String, Function],
    },
    range: {
      type: Boolean,
    },
    allowedDates: {
      type: Function,
      default: () => true,
    },
  },
  setup: (props, { emit }) => {
    const menuOpen = ref(false);

    const onInput = (value: Array<string> | string) => {
      emit(
        'input',
        Array.isArray(value) ? formatDateRange(value) : formatDate(value),
      );
    };

    const onClear = () => {
      emit('input', '');
    };

    const computedDatepickerValue = computed(() =>
      props.range
        ? parseDates((props.value as string) || '', true)
        : parseDate((props.value as string) || ''),
    );

    const minValue = computed(() =>
      typeof props.min === 'function'
        ? props.min(props.assistantContext)
        : props.min,
    );

    const maxValue = computed(() =>
      typeof props.max === 'function'
        ? props.max(props.assistantContext)
        : props.max,
    );

    return {
      computedDatepickerValue,
      maxValue,
      menuOpen,
      minValue,
      onClear,
      onInput,
    };
  },
});

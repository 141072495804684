import i18n from '@/plugins/i18n';

// This function converts an underscore formatted string to a camelCase string
// Example: under_score_string ----> underScoreString
const underscoreToCamelCase = (string: string) =>
  string.replace(/_([a-z])/g, (x, up) => up.toUpperCase());

// This function converts an array with strings from a Shopware object to a comma separated string of translations
// Example: ["modbus_tcp", "ocpp"] ----> "ModbusTCP, OCPP"
export const wallboxDataStringArrayToString = (
  array: string[] | undefined | null,
  translationPath?: string,
): string | null => {
  if (array === undefined || !array || array.length === 0) {
    return null;
  }
  const translationsArray = array.map((object) =>
    i18n.t(`${translationPath}.${underscoreToCamelCase(object)}`),
  );
  return translationsArray?.join(', ');
};

// This function converts an array with numbers from a Shopware object to a comma separated string
// The suffix is added after each entry ([16, 32] ----> "16 A, 32 A")
// The divideBy operator divides each entry by the number ([7400, 11000, 22000] ----> "7.4 kW, 11 kW, 22 kW"
export const wallboxDataNumberArrayToString = (
  array: number[] | undefined | null,
  translationPath?: string,
  suffix?: string,
  divideBy?: number,
): string | null => {
  if (array === undefined || !array || array.length === 0) {
    return null;
  }
  // Sort the numbers (low to high) because sometimes they aren't already
  const sortedArray = [...array]?.sort((one, two) => one - two);
  // Build a string array from the numbers array
  const stringArray = sortedArray?.map((object) => {
    let value: number = object;
    if (divideBy !== undefined && divideBy) {
      value = value / divideBy;
    }
    return suffix !== undefined && suffix
      ? String(value) + ` ${suffix}`
      : String(value);
  });
  return stringArray?.join(', ');
};

import { render, staticRenderFns } from "./WallboxList.vue?vue&type=template&id=490a4ab9&"
import script from "./WallboxList.vue?vue&type=script&lang=ts&"
export * from "./WallboxList.vue?vue&type=script&lang=ts&"
import style0 from "./WallboxList.vue?vue&type=style&index=0&id=490a4ab9&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
installComponents(component, {VAlert,VProgressCircular})

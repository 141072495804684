import { StepId } from '~/consts/assistant-steps';
import { StepDefinition } from '@/interfaces/step-definition';
import { StepTypes } from '@/consts/step-types';
import { getProductRequest } from '~/steps/summary/helper';
import { sendProductRequest } from '@/lib/submit/send-product-request';
import { uploadFiles } from '@/lib/submit/upload-files';
import { getFilesFromContext } from '@/lib/xstate/machine';
import { getRequestCommentFromContext } from '~/helper/request-comment';

const config: StepDefinition = {
  id: StepId.SUMMARY,
  type: StepTypes.SUMMARY,
  submit: async ({ i18n, steps, assistantContext }): Promise<void> => {
    try {
      const contextFiles = getFilesFromContext(assistantContext);
      const s3UploadedFilePaths = await uploadFiles(contextFiles);

      /*
      ###############################################################################
      ########################### send product request ##############################
      ###############################################################################
      */

      const requestComment = getRequestCommentFromContext(
        steps,
        assistantContext,
      );
      const productId = process.env.VUE_APP_SHOP_PRODUCT_ID;
      const productRequest = getProductRequest(
        assistantContext,
        requestComment,
        productId,
      );

      await sendProductRequest({
        productRequest,
        shopHost: process.env.VUE_APP_STAWAG_HOST,
        shopApiKey: process.env.VUE_APP_STAWAG_API_KEY,
        shopUseBasicAuth: process.env.VUE_APP_STAWAG_USE_BASIC_AUTH === 'true',
        shopBasicAuth:
          process.env.VUE_APP_STAWAG_USE_BASIC_AUTH === 'true'
            ? process.env.VUE_APP_STAWAG_BASIC_AUTH
            : undefined,
        disableCustomerMail:
          process.env.VUE_APP_SHOP_DISABLE_CUSTOMER_MAIL === 'true',
        s3BucketName: process.env.VUE_APP_ASSISTANT_S3_BUCKET,
        s3FileAttachmentPaths: s3UploadedFilePaths,
      });

      return;
    } catch (error) {
      let errorMessage = i18n.t('message.error').toString();
      if (error instanceof Error) {
        errorMessage = error.message;
      }

      // eslint-disable-next-line no-console
      console.error(errorMessage);

      throw new Error(errorMessage);
    }
  },
};

export default config;

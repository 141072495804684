















































import { defineComponent, ref } from '@vue/composition-api';
import AssistantStep from '@/components/AssistantStep.vue';
import GcForm from '@/components/primitives/GcForm.vue';
import AssistantStepNavigation from '@/components/AssistantStepNavigation.vue';
import { getStepContext } from '@/lib/context';
import { stepComponentProps } from '@/lib/steps/helper';
import FormField from '@/components/FormField.vue';
import { useBaseTransitions } from '@/lib/xstate/transitions';
import GcRow from '@/components/primitives/GcRow.vue';
import GcCol from '@/components/primitives/GcCol.vue';
import GcImage from '@/components/primitives/GcImage.vue';

export default defineComponent({
  components: {
    AssistantStep,
    AssistantStepNavigation,
    FormField,
    GcForm,
    GcRow,
    GcCol,
    GcImage,
  },
  props: {
    ...stepComponentProps,
  },
  setup: (props, { emit }) => {
    const { onNext, onPrev } = useBaseTransitions(
      emit,
      props.assistantContext,
      props.step,
    );
    const formData = getStepContext(props.assistantContext, props.step);
    const formValid = ref(false);
    const showBackButton = () =>
      props.step && props.steps && props.steps.indexOf(props.step) > 0;
    const baseUrl = process.env.VUE_APP_BASE_URL;
    return {
      formData,
      formValid,
      onNext,
      onPrev,
      showBackButton,
      baseUrl,
    };
  },
});

// Here the exact ids of all the wallboxes to load are specified
export const ids: string[] = [
  'af2538a9b37c4679af9ec9215d69a5b7',
  '4a5992889020425190481517181ea806',
  '1c0412f44a4e4d1ba94c0be3f229caa5',
  '9703409879d748318384c2c4558222f5',
  '16734e52fc5c46acb7048f18616764e2',
  '22813545a8574b43b56eb40896ea3493',
  '61b49642d8334835908dbcc4806fa1b6',
];

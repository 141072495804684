import {
  GcProductWallboxDataExtension,
  GcSwProduct,
} from '@/lib/greenportal/load-products';
import { AssistantContext } from '~/steps/steps';
import { getNumberOfSimilarAttributes } from '~/helper/compare-wallboxes';

/**
 * Filter all available wallboxes by context
 */
export const filteredWallboxes = (
  context: AssistantContext,
  wallboxes: GcSwProduct[],
): GcSwProduct[] => {
  const contextWallboxData: GcProductWallboxDataExtension = {
    outdoorReady: context.location.lisArea.includes('outdoorArea'),
    billable: context.information.settleUser.includes('yes'),
  };
  return [...wallboxes].filter(
    // If there is at least one property matching the context return this wallbox
    (wallbox) => getNumberOfSimilarAttributes(contextWallboxData, wallbox) >= 1,
  );
};

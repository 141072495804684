import { ref } from '@vue/composition-api';
import {
  GcProductWallboxDataExtension,
  GcSwProduct,
} from '@/lib/greenportal/load-products';
import i18n from '@/plugins/i18n';
import {
  wallboxDataNumberArrayToString,
  wallboxDataStringArrayToString,
} from '@/lib/greenportal/wallbox-data-array-to-string';

const wallboxModalOpen = ref(false);
const selectedWallbox = ref<null | GcSwProduct>(null);
const wallboxData = ref<null | GcProductWallboxDataExtension>(null);

export const useWallboxModal = () => {
  const openWallboxModal = (wallbox: GcSwProduct) => {
    selectedWallbox.value = wallbox;
    wallboxModalOpen.value = true;
    wallboxData.value = selectedWallbox.value?.extensions.gcProductWallboxData
      ? selectedWallbox.value?.extensions.gcProductWallboxData[0]
      : null;
  };

  const closeWallboxModal = () => {
    wallboxModalOpen.value = false;
  };

  // Example for following function
  // Input: ["lan", "sim"]
  // Output: "LAN, SIM"
  const networkConnectors = () =>
    wallboxDataStringArrayToString(
      wallboxData.value?.networkConnectors,
      'wallboxData.networkConnectors.options',
    );

  // Example for following function
  // Input: [7400, 11000, 22000]
  // Output: "7.4 kW, 11 kW, 22 kW"
  const chargingPowersWatt = () =>
    wallboxDataNumberArrayToString(
      wallboxData.value?.chargingPowersWatt,
      undefined,
      'kW',
      1000,
    );

  // Example for following function
  // Input: [16, 32]
  // Output: "16 A, 32 A"
  const amperePowers = () =>
    wallboxDataNumberArrayToString(
      wallboxData.value?.electricCurrentsAmpere,
      undefined,
      'A',
    );

  // Example for following function
  // Input: [230, 400]
  // Output: "230 V, 400 V"
  const voltages = () =>
    wallboxDataNumberArrayToString(
      wallboxData.value?.voltagesVolt,
      undefined,
      'V',
    );

  // Example for following function
  // Input: 3
  // Output: "3-phasig"
  const phases = () => {
    if (wallboxData.value?.noOfPhases) {
      return i18n
        .t(
          `wallboxData.noOfPhases.options.option${wallboxData.value.noOfPhases}`,
        )
        .toString();
    }
    return null;
  };

  // Example for following function
  // Input: ["dc_protection", "fi_type_a_with_dc_protection"]
  // Output: "DC Schutz, FI Typ A mit DC Schutz"
  const electricProtectionDevices = () =>
    wallboxDataStringArrayToString(
      wallboxData.value?.electricProtectionDevices,
      'wallboxData.electricProtectionDevices.options',
    );

  // Example for following function
  // Input: ["modbus_tcp", "ocpp"]
  // Output: "ModbusTCP, OCPP"
  const protocols = () =>
    wallboxDataStringArrayToString(
      wallboxData.value?.protocols,
      'wallboxData.protocols.options',
    );

  // Example for following function
  // Input: ["ip55"]
  // Output: "IP55"
  const protectionClasses = () => {
    if (!wallboxData.value?.protectionClasses) {
      return null;
    }
    const translations = wallboxData.value?.protectionClasses?.map((object) =>
      object.toUpperCase(),
    );
    return translations?.join(', ');
  };

  // Example for following function
  // Input: 2
  // Output: "2 Ladepunkte"
  const noOfChargingPoints = () => {
    if (wallboxData.value?.noOfChargingPoints) {
      return i18n
        .t(
          `wallboxData.noOfChargingPoints.options.option${wallboxData.value.noOfChargingPoints}`,
        )
        .toString();
    }
    return null;
  };

  // Example for following function
  // Input: ["type_2_socket", "type_2_cable"]
  // Output: "Typ 2 Steckdose, Typ 2 Ladekabel"
  const chargingConnectorTypes = () => {
    if (!wallboxData.value?.chargingConnectorTypes) {
      return null;
    }
    const translations = wallboxData.value?.chargingConnectorTypes?.map(
      (object) =>
        i18n
          .t(
            `wallboxData.chargingConnectorTypes.selectOptions.${object.replaceAll(
              '_',
              '',
            )}`,
          )
          .toString(),
    );
    return translations?.join(', ');
  };

  // Example for following function
  // Input: ["pillar_mount", "wall_mount"]
  // Output: "Standsäule, Wandmontage"
  const installationOptions = () =>
    wallboxDataStringArrayToString(
      wallboxData.value?.installationOptions,
      'wallboxData.installationOptions.selectOptions',
    );

  // Example for following function
  // Input: 8
  // Output: "8 m"
  const cableLengthMeter = () => {
    if (!wallboxData.value?.cableLengthMeter) {
      return null;
    }
    return wallboxData.value.cableLengthMeter.toString() + ' m';
  };

  // Example for following function
  // Input: ["led"]
  // Output: "LED"
  const displayTypes = () =>
    wallboxDataStringArrayToString(
      wallboxData.value?.displayTypes,
      'wallboxData.displayTypes.selectOptions',
    );

  // Example for following function
  // Input: ["rfid", "key_switch", "app"]
  // Output: "RFID, Schlüsselschalter, App"
  const accessProtectionOptions = () =>
    wallboxDataStringArrayToString(
      wallboxData.value?.accessProtectionOptions,
      'wallboxData.accessProtectionOptions.selectOptions',
    );

  // Example for following function
  // Input: ["calibrated", "uncalibrated"]
  // Output: "Eichrechtskonform, Ungeeicht"
  const energyMeters = () =>
    wallboxDataStringArrayToString(
      wallboxData.value?.energyMeters,
      'wallboxData.energyMeters.selectOptions',
    );

  return {
    closeWallboxModal,
    openWallboxModal,
    selectedWallbox,
    wallboxModalOpen,
    wallboxData,
    networkConnectors,
    chargingPowersWatt,
    amperePowers,
    voltages,
    phases,
    electricProtectionDevices,
    protocols,
    protectionClasses,
    noOfChargingPoints,
    chargingConnectorTypes,
    installationOptions,
    cableLengthMeter,
    displayTypes,
    accessProtectionOptions,
    energyMeters,
  };
};

import { AssistantContext } from '~/steps/steps';
import { getFormattedData } from '@/lib/formatter/context';
import { StepDefinition } from '@/interfaces/step-definition';
import { StepId } from '~/consts/assistant-steps';

// This function creates the comment for Shopware from context data
export const getRequestCommentFromContext = (
  steps: StepDefinition[],
  assistantContext: AssistantContext,
): string => {
  let commentString = '';
  const summary = getFormattedData({
    assistantContext,
    steps,
  });
  summary.forEach((summaryCategory) => {
    summaryCategory.steps.forEach((summaryStep) => {
      if (summaryStep.id === StepId.CONTACT_FORM) {
        return;
      }
      summaryStep.fields.forEach((field) => {
        if (field.formattedLabel === false && field.formattedValue === false) {
          return;
        }
        if (field.formattedLabel === 'Upload') {
          return;
        }
        if (field.formattedLabel !== false) {
          commentString += `${field.formattedLabel}: `;
        }
        if (field.formattedValue !== false) {
          commentString += field.formattedValue;
        }
        commentString += '\n\n';
      });
    });
  });
  return commentString;
};

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('assistant-step',{attrs:{"title":_vm.$t(((_vm.step.id) + ".title"))}},[_c('gc-form',{on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit()}},model:{value:(_vm.formValid),callback:function ($$v) {_vm.formValid=$$v},expression:"formValid"}},[_c('gc-row',[_c('gc-col',{attrs:{"cols":"12","sm":"12"}},[_c('form-field',{attrs:{"form-data":_vm.formData,"field-name":"companyName","step":_vm.step}})],1)],1),_c('gc-row',[_c('gc-col',{attrs:{"cols":"12","sm":"6"}},[_c('form-field',{attrs:{"form-data":_vm.formData,"field-name":"firstname","step":_vm.step}})],1),_c('gc-col',{attrs:{"cols":"12","sm":"6"}},[_c('form-field',{attrs:{"form-data":_vm.formData,"field-name":"lastname","step":_vm.step}})],1)],1),_c('gc-row',[_c('gc-col',{attrs:{"cols":"12","sm":"9"}},[_c('form-field',{attrs:{"form-data":_vm.formData,"field-name":"street","step":_vm.step}})],1),_c('gc-col',{attrs:{"cols":"12","sm":"3"}},[_c('form-field',{attrs:{"form-data":_vm.formData,"field-name":"streetNumber","step":_vm.step}})],1)],1),_c('gc-row',[_c('gc-col',{attrs:{"cols":"12","sm":"4"}},[_c('form-field',{attrs:{"form-data":_vm.formData,"field-name":"zipcode","step":_vm.step}})],1),_c('gc-col',{attrs:{"cols":"12","sm":"8"}},[_c('form-field',{attrs:{"form-data":_vm.formData,"field-name":"city","step":_vm.step}})],1)],1),_c('gc-row',[_c('gc-col',[_c('form-field',{attrs:{"form-data":_vm.formData,"field-name":"phone","step":_vm.step}})],1)],1),_c('gc-row',[_c('gc-col',[_c('form-field',{attrs:{"form-data":_vm.formData,"field-name":"email","step":_vm.step}})],1)],1),_c('gc-row',[_c('gc-col',[_c('form-field',{attrs:{"form-data":_vm.formData,"field-name":"installAddressDiffers","step":_vm.step,"assistantContext":_vm.assistantContext}})],1)],1),(
        _vm.assistantContext.contactForm.installAddressDiffers.includes('yes')
      )?_c('gc-row',[_c('gc-col',[_c('label',{staticClass:"gc-block gc-text-sm gc-relative"},[_vm._v(_vm._s(_vm.$t(((_vm.step.id) + ".formFields.installAddressLabel.label"))))])])],1):_vm._e(),(
        _vm.assistantContext.contactForm.installAddressDiffers.includes('yes')
      )?_c('gc-row',[_c('gc-col',{attrs:{"cols":"12","sm":"9"}},[_c('form-field',{attrs:{"form-data":_vm.formData,"field-name":"installAddressStreet","step":_vm.step}})],1),_c('gc-col',{attrs:{"cols":"12","sm":"3"}},[_c('form-field',{attrs:{"form-data":_vm.formData,"field-name":"installAddressStreetNumber","step":_vm.step}})],1)],1):_vm._e(),(
        _vm.assistantContext.contactForm.installAddressDiffers.includes('yes')
      )?_c('gc-row',[_c('gc-col',{attrs:{"cols":"12","sm":"4"}},[_c('form-field',{attrs:{"form-data":_vm.formData,"field-name":"installAddressZipcode","step":_vm.step}})],1),_c('gc-col',{attrs:{"cols":"12","sm":"8"}},[_c('form-field',{attrs:{"form-data":_vm.formData,"field-name":"installAddressCity","step":_vm.step}})],1)],1):_vm._e(),_c('gc-row',[_c('gc-col',[_c('form-field',{attrs:{"form-data":_vm.formData,"field-name":"privacyConfirmation","step":_vm.step,"hide-label":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{domProps:{"innerHTML":_vm._s(
                _vm.$t(((_vm.step.id) + ".formFields.privacyConfirmation.headline"))
              )},on:{"click":function($event){$event.stopPropagation();}}})]},proxy:true}])})],1)],1)],1),_c('assistant-step-navigation',{attrs:{"assistantContext":_vm.assistantContext,"step":_vm.step,"steps":_vm.steps,"submit":_vm.step.submit,"valid":_vm.formValid,"show-back-button":true},on:{"next":function($event){return _vm.onNext()},"prev":function($event){return _vm.onPrev()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
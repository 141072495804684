
















import { faPlugCircleCheck } from '@fortawesome/pro-solid-svg-icons';
import { defineComponent, ref } from '@vue/composition-api';
import AssistantStep from '@/components/AssistantStep.vue';
import AssistantStepNavigation from '@/components/AssistantStepNavigation.vue';
import FormField from '@/components/FormField.vue';
import GcAlert from '@/components/primitives/GcAlert.vue';
import GcButton from '@/components/primitives/GcButton.vue';
import GcCard from '@/components/primitives/GcCard.vue';
import GcCol from '@/components/primitives/GcCol.vue';
import GcForm from '@/components/primitives/GcForm.vue';
import { stepComponentProps } from '@/lib/steps/helper';
import { useBaseTransitions } from '@/lib/xstate/transitions';
import WallboxList from '~/components/wallbox-list/WallboxList.vue';

export default defineComponent({
  components: {
    WallboxList,
    AssistantStep,
    AssistantStepNavigation,
    FormField,
    GcAlert,
    GcButton,
    GcCard,
    GcCol,
    GcForm,
  },
  props: {
    ...stepComponentProps,
  },
  setup: (props, { emit }) => {
    const { onNext, onPrev } = useBaseTransitions(
      emit,
      props.assistantContext,
      props.step,
    );
    const formValid = ref<boolean>(false);

    return {
      faPlugCircleCheck,
      formValid,
      onNext,
      onPrev,
    };
  },
});

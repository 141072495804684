import consultation, { ConsultationStepContext } from '~/steps/consultation';
import contactForm, { ContactFormStepContext } from '~/steps/contact-form';
import documents, { DocumentsStepContext } from '~/steps/documents';
import information, { InformationStepContext } from '~/steps/information';
import location, { LocationStepContext } from '~/steps/location';
import summary from '~/steps/summary';
import wallboxes from '~/steps/wallboxes';

// Add the imported StepDefinition from the steps in the order
// the steps should be shown in the assistant.
const steps = [
  location,
  information,
  documents,
  consultation,
  contactForm,
  summary,
  wallboxes,
];

// Add the imported context interface for the steps
export interface AssistantContext {
  location: LocationStepContext;
  information: InformationStepContext;
  documents: DocumentsStepContext;
  consultation: ConsultationStepContext;
  contactForm: ContactFormStepContext;
}

export default steps;

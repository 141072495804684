






































import { PropType, computed, defineComponent, ref } from '@vue/composition-api';
import { VDialog } from 'vuetify/lib';
import GcDatePicker from '@/components/primitives/GcDatePicker.vue';
import GcMenu from '@/components/primitives/GcMenu.vue';
import GcTextField from '@/components/primitives/GcTextField.vue';
import GcTimePicker from '@/components/primitives/GcTimePicker.vue';
import { AssistantContext } from '~/steps/steps';

export default defineComponent({
  components: {
    GcDatePicker,
    GcTimePicker,
    GcMenu,
    VDialog,
    GcTextField,
  },
  methods: {
    // eslint-disable-next-line prefer-arrow-functions/prefer-arrow-functions
    reset: function () {
      this.time = null;
    },
  },
  props: {
    assistantContext: {
      default: () => ({}),
      type: Object as PropType<AssistantContext>,
      required: true,
    },
    allowedMinutes: {
      default: () => true,
      type: Function,
    },
    allowedHours: {
      default: () => true,
      type: Function,
    },
    rules: {
      default: () => [],
      type: Array,
    },
    disabled: {
      default: () => false,
      type: Boolean,
    },
  },
  setup: (props, { emit }) => {
    const onTimeInput = (value: string) => {
      emit('input', value);
    };

    const allowedHours = props.allowedHours as (
      hour: number,
      context: AssistantContext,
    ) => boolean;

    const allowedMinutes = props.allowedMinutes as (
      minute: number,
      hour: number,
      context: AssistantContext,
    ) => boolean;

    const customAllowedHours = computed(
      () => (hour: number) => allowedHours(hour, props.assistantContext),
    );

    const customAllowedMinutes = computed(
      () => (minute: number) =>
        allowedMinutes(minute, selectedHour.value, props.assistantContext),
    );

    const selectedHour = ref<number>(-1);
    const onHourChange = (hour: number) => {
      selectedHour.value = hour;
    };

    return {
      onTimeInput: onTimeInput,
      time: null,
      modelIsOpen: false,
      customAllowedHours,
      customAllowedMinutes,
      onHourChange,
    };
  },
});

<template>
  <div class="vehicle-details__item d-flex align-center justify-start mb-3">
    <font-awesome-icon v-if="icon" :icon="icon" class="mr-3 primary" />
    <div>
      <small class="text-caption text--secondary d-block">{{ label }}</small>
      <span class="text--primary"><slot /></span>
    </div>
  </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

export default {
  props: {
    icon: {
      required: false,
      type: FontAwesomeIcon,
    },
    label: {
      type: String,
    },
  },
};
</script>

<style lang="scss">
.vehicle-details__item {
  line-height: 1;
}
</style>

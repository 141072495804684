import {
  faChartLineUp,
  faFire,
  faPlugCircleBolt,
  faSolarPanel,
} from '@fortawesome/pro-duotone-svg-icons';
import { StepId } from '~/consts/assistant-steps';
import {
  FormMultipleChoiceValueType,
  StepDefinition,
} from '@/interfaces/step-definition';
import { AssistantStepContext } from '@/interfaces/context';
import { buildFormMultipleChoiceField } from '@/lib/forms/builder';
import i18n from '@/plugins/i18n';

export interface ConsultationStepContext extends AssistantStepContext {
  consultationChoice: FormMultipleChoiceValueType;
}

const config: StepDefinition = {
  fields: [
    buildFormMultipleChoiceField('consultationChoice', {
      component: {
        options: [
          {
            icon: faPlugCircleBolt,
            value: 'energySupply',
          },
          {
            icon: faChartLineUp,
            value: 'energyEfficiency',
          },
          {
            icon: faSolarPanel,
            value: 'photovoltaicStorage',
          },
          {
            icon: faFire,
            value: 'heat',
          },
        ],
        columns: 2,
        verticalOptionLayout: true,
      },
      required: false,
      outputFormatter: (data) => ({
        formattedLabel: i18n.t(
          'consultation.formFields.consultationChoice.formattedLabel',
        ) as string,
        formattedValue:
          data?.formattedValue !== '–'
            ? data.formattedValue
            : (i18n.t(
                'consultation.formFields.consultationChoice.no',
              ) as string),
      }),
    }),
  ],

  id: StepId.CONSULTATION,
};

export default config;

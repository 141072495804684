/* eslint-disable sort-keys-fix/sort-keys-fix */
export default {
  start: {
    buttonText: 'Assistenten starten',
    description:
      'Sie planen Ihre Flotte zu elektrifizieren und Ihre Standorte mit Ladeinfrastruktur auszustatten? Wir entwickeln für Sie ein entsprechendes Standortkonzept. Mit unserem LIS-Standortassistenten helfen Sie uns, für Sie ein passendes Angebot zu erstellen!',
    title: 'LIS-Standortassistent',
    step: {
      label: 'Start',
    },
  },
  location: {
    title: 'Standort',
    step: {
      label: 'Standort',
    },
    label: 'An welchem Standort möchten Sie eine Ladeinfrastruktur errichten?',
    formFields: {
      street: {
        label: 'Straße',
      },
      streetNumber: {
        label: 'Hausnummer',
      },
      zipcode: {
        label: 'Postleitzahl',
      },
      city: {
        label: 'Ort',
      },
      lisArea: {
        options: {
          undergroundCarPark: {
            label: 'Tiefgarage',
          },
          outdoorArea: {
            label: 'Außenbereich',
          },
          both: {
            label: 'Beides',
          },
          other: {
            label: 'Andere',
          },
        },
        label:
          'In welchem Bereich soll die Ladeinfrastruktur aufgebaut werden?',
        formattedLabel: 'Zielbereich der Ladeinfrastruktur',
      },
      customLisArea: {
        label: 'Anderer Bereich:',
      },
      alreadyHasLis: {
        label:
          'An diesem Standort wird bereits eine Ladeinfrastruktur betrieben',
        formattedLabel: 'Ladeinfrastruktur bereits vorhanden',
        component: {
          label:
            'An diesem Standort wird bereits eine Ladeinfrastruktur betrieben',
        },
        yes: 'Ja',
        no: 'Nein',
      },
      threeKW: {
        label: '3,7 kW (ca. 24 km pro Stunde)',
        formattedLabel: 'Anzahl Ladepunkte mit 3,7 kW',
      },
      elevenKW: {
        label: '11 kW (ca. 70 km pro Stunde)',
        formattedLabel: 'Anzahl Ladepunkte mit 11 kW',
      },
      twentyKW: {
        label: '22 kW (ca. 140 km pro Stunde)',
        formattedLabel: 'Anzahl Ladepunkte mit 22 kW',
      },
      fiftyKW: {
        label: '50 kW (ca. 318 km pro Stunde)',
        formattedLabel: 'Anzahl Ladepunkte mit 50 kW',
      },
    },
    loadingSpeed:
      'Wählen Sie Ihre Ladegeschwindigkeit und die Anzahl der Ladepunkte für diesen Standort',
    dealBreaker: {
      text: 'Für mindestens eine der Ladegeschwindigkeiten muss eine Anzahl von Ladepunkten festgelegt werden.',
    },
  },
  information: {
    title: 'Informationen',
    step: {
      label: 'Informationen',
    },
    formFields: {
      settleUser: {
        label: 'Sollen die Nutzer abgerechnet werden?',
        formattedLabel: 'Nutzer abrechnen',
        options: {
          yes: {
            label: 'Ja',
          },
          no: {
            label: 'Nein',
          },
        },
      },
      powerKW: {
        label: 'Wie hoch ist die Anschlussleistung Ihres Hausanschlusses?',
        formattedLabel: 'Anschlussleistung des Hausanschlusses',
        helperDialog: {
          text: 'Diese Angabe hilft uns bei der Planung, Sie können dieses Feld aber auch leer lassen, wenn Ihnen diese Information nicht vorliegt.',
        },
      },
      internetConnection: {
        label:
          'Steht eine Internetverbindung an dem geplanten Standort zur Verfügung?',
        formattedLabel: 'Verfügbare Internetverbindung am Standort',
        helperDialog: {
          text: 'Wir empfehlen in der Regel die Anbindung der Wallboxen über LTE. Um dies umzusetzen muss jedoch eine ausreichende Empfangsqualität am Standort gegeben sein.',
        },
        options: {
          lan: {
            label: 'LAN',
          },
          wlan: {
            label: 'WLAN',
          },
          lte: {
            label: 'LTE',
          },
        },
      },
      propertyOwner: {
        label: 'Sind Sie der Grundstückseigentümer?',
        formattedLabel: 'Grundstückseigentümer',
        options: {
          yes: {
            label: 'Ja',
          },
          no: {
            label: 'Nein',
          },
        },
      },
      branchOfIndustry: {
        label: 'In welcher Branche sind Sie tätig?',
        formattedLabel: 'Tätigkeitsbranche',
        options: {
          housingIndustry: {
            label: 'Wohnungswirtschaft',
          },
          handwork: {
            label: 'Handwerk',
          },
          ownBusiness: {
            label: 'Gewerbe',
          },
          hotel: {
            label: 'Hotel',
          },
          gastronomy: {
            label: 'Gastronomie',
          },
          parkingOperator: {
            label: 'Parkplatzbetreiber',
          },
          other: {
            label: 'Sonstiges',
          },
        },
      },
      customBranchOfIndustry: {
        label: 'Sonstige Branche',
      },
    },
  },
  documents: {
    title: 'Dokumente',
    step: {
      label: 'Dokumente',
    },
    formFields: {
      uploadDocuments: {
        label: 'Upload',
        htmlDescription:
          'Bitte laden Sie hier Pläne und / oder Fotos des Bereiches hoch, auf denen die gewünschte Position der Ladestationen erkennbar ist. Gerne können Sie uns auch unter "Bemerkungen" den Link zu Ihrem Standort auf <a class="gc-text-primary" href="https://www.google.de/maps" target="_blank">Google Maps</a> oder <a class="gc-text-primary" href="https://www.openstreetmap.de/karte" target="_blank">OpenStreetMap</a> schicken.',
        helperDialog: {
          title: 'Ein Beispiel könnte so aussehen:',
        },
      },
      additionalComments: {
        label: 'Bemerkungen',
        helperDialog: {
          text: 'Bitte teilen Sie uns weitere Informationen mit, die für Ihr Projekt relevant sein könnten.',
        },
      },
    },
  },
  wallboxes: {
    title: 'Wallboxen',
    step: {
      label: 'Wallboxen',
    },
    description:
      'Vielen Dank für Ihre Anfrage. Die für Sie am besten geeignete Wallbox werden wir gemeinsam mit Ihnen ermitteln. Hier können Sie sich jedoch schon einmal einen Überblick über mögliche Wallboxmodelle verschaffen.',
    loadingError: 'Produkte konnten nicht geladen werden.',
    loadingMessage: 'Produkte werden geladen …',
    noProductsFound:
      'Leider gibt es keine Produkte, die Ihren Kriterien entsprechen.',
    moreInformation: 'Weitere Informationen',
    modal: {
      button: {
        close: 'Schließen',
      },
      details: {
        general: {
          label: 'Allgemein',
          chargingPowerConfigurable: 'Ladeleistung konfigurierbar',
          electricCurrentConfigurable: 'Stromstärke konfigurierbar',
        },
      },
      extras: {
        label: 'Extras',
      },
      chargingPoints: {
        label: 'Ladepunkte',
      },
      dimensions: {
        label: 'Produktmaße',
        width: 'Breite',
        height: 'Höhe',
        length: 'Länge',
        weight: 'Gewicht',
      },
    },
  },
  consultation: {
    title: 'Beratung',
    step: {
      label: 'Beratung',
    },
    formFields: {
      consultationChoice: {
        label: 'Haben Sie Interesse an einer Beratung?',
        formattedLabel: 'Interesse an Beratung im Bereich',
        options: {
          energySupply: {
            label: 'Energiebezug',
          },
          energyEfficiency: {
            label: 'Energieeffizienzberatung',
          },
          photovoltaicStorage: {
            label: 'Photovoltaik/Speicher',
          },
          heat: {
            label: 'Wärme',
          },
        },
        no: 'Nein',
      },
    },
  },
  contactForm: {
    title: 'Ihre Kontaktdaten',
    step: {
      label: 'Kontaktformular',
    },
    formFields: {
      firstname: {
        label: 'Vorname',
      },
      lastname: {
        label: 'Nachname',
      },
      street: {
        label: 'Straße',
      },
      streetNumber: {
        label: 'Hausnr.',
      },
      zipcode: {
        label: 'PLZ',
      },
      city: {
        label: 'Stadt',
      },
      phone: {
        label: 'Telefonnummer',
      },
      email: {
        label: 'E-Mail-Adresse',
      },
      alreadyCustomer: {
        label: 'Sind Sie bereits STAWAG-Kunde?',
        options: {
          yes: {
            label: 'Ja',
          },
          no: {
            label: 'Nein',
          },
        },
      },
      privacyConfirmation: {
        component: {
          label:
            'Hiermit stimme ich der <a href="#" target="_blank">Datenschutzerklärung</a> zu.',
        },
      },
    },
  },
  done: {
    title: 'Fertig',
    step: {
      label: 'Fertig',
    },
  },
};
/* eslint-enable sort-keys-fix/sort-keys-fix */

<script>
import { VCardText } from 'vuetify/lib';
export default VCardText.extend({
  props: {
    elevation: {
      default: 0,
      type: [String, Number],
    },
  },
});
</script>

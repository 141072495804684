import { Buffer } from 'buffer';
import axios from 'axios';

import { AssistantContext } from '~/steps/steps';
import { FileData } from '@/lib/submit/send-mail';
import { ProductRequest } from '@/lib/submit/send-product-request';

const MAIL_TEMPLATE_DIRECTORY = 'mail-templates';

export const getMailTemplate = async (): Promise<string> => {
  const mailTemplateFilePath = `${MAIL_TEMPLATE_DIRECTORY}/${process.env.VUE_APP_TENANT_ACRONYM}.html`;

  try {
    const response = await axios.get(
      `${process.env.VUE_APP_BASE_URL}/${mailTemplateFilePath}`,
    );

    return response.data;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e);
    throw new Error(
      `Could not load mail template "${process.env.VUE_APP_BASE_URL}/${mailTemplateFilePath}"`,
    );
  }
};

export const getFileAttachments = async (
  attachments: Array<string>,
): Promise<Array<FileData>> => {
  const files: Array<FileData | Record<string, unknown>> = await Promise.all(
    attachments.map(
      async (
        attachment: string,
      ): Promise<FileData | Record<string, unknown>> => {
        try {
          const response = await axios.get(
            `${process.env.VUE_APP_BASE_URL}/${attachment}`,
            { responseType: 'arraybuffer' },
          );

          const fileAttachmentContentType = response.headers['content-type'];

          const fileAttachmentData = Buffer.from(
            response.data,
            'binary',
          ).toString('base64');

          return {
            filename: attachment.split('/').pop() ?? '',
            contentType: fileAttachmentContentType,
            content: fileAttachmentData,
            encoding: 'base64',
          };
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error(error);
          // eslint-disable-next-line no-console
          console.error(
            `Could not get file "${process.env.VUE_APP_BASE_URL}/${attachment}"`,
          );

          return {};
        }
      },
    ),
  );

  return files.filter((file) =>
    Object.hasOwnProperty.call(file, 'filename'),
  ) as Array<FileData>;
};

export const getProductRequest = (
  assistantContext: AssistantContext,
  comment: string,
  productId: string,
  salutation?: 'Herr' | 'Frau' | 'Keine Angabe',
): ProductRequest => {
  const contactFormData = assistantContext.contactForm;

  return {
    city: contactFormData.city,
    comment,
    email: contactFormData.email,
    firstName: contactFormData.firstname,
    lastName: contactFormData.lastname,
    phone: contactFormData.phone,
    productId,
    productUrl: `${process.env.VUE_APP_STAWAG_HOST}/detail/${productId}`,
    salutationDisplayName: salutation ?? 'Keine Angabe',
    street: `${contactFormData.street} ${contactFormData.streetNumber}`,
    zipCode: contactFormData.zipcode,
  };
};

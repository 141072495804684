import { faThumbsDown, faThumbsUp } from '@fortawesome/pro-duotone-svg-icons';
import component from './ContactForm.vue';
import { StepId } from '~/consts/assistant-steps';
import {
  FormCheckboxValueType,
  FormTextFieldValueType,
  StepDefinition,
} from '@/interfaces/step-definition';
import { AssistantStepContext } from '@/interfaces/context';
import {
  buildFormCheckbox,
  buildFormMultipleChoiceField,
  buildFormTextField,
} from '@/lib/forms/builder';
import { ValidationType } from '@/consts/validation-types';
import { StepTypes } from '@/consts/step-types';

export interface ContactFormStepContext extends AssistantStepContext {
  companyName: FormTextFieldValueType;
  firstname: FormTextFieldValueType;
  lastname: FormTextFieldValueType;
  street: FormTextFieldValueType;
  streetNumber: FormTextFieldValueType;
  zipcode: FormTextFieldValueType;
  city: FormTextFieldValueType;
  phone: FormTextFieldValueType;
  email: FormTextFieldValueType;
  privacyConfirmation: FormCheckboxValueType;
}

const config: StepDefinition = {
  component,
  fields: [
    buildFormTextField('companyName', {
      outputFormatter: (data) => ({
        formattedLabel:
          data?.formattedValue !== '–' ? data.formattedLabel : false,
        formattedValue:
          data?.formattedValue !== '–' ? data.formattedValue : false,
      }),
    }),
    buildFormTextField('firstname', {
      required: true,
    }),
    buildFormTextField('lastname', {
      required: true,
    }),
    buildFormTextField('street', {
      required: true,
    }),
    buildFormTextField('streetNumber', {
      required: true,
    }),
    buildFormTextField('zipcode', {
      required: true,
      validation: [ValidationType.postalCodeDE],
    }),
    buildFormTextField('city', {
      required: true,
    }),
    buildFormTextField('phone', {
      required: true,
      validation: [ValidationType.phoneNumber],
    }),
    buildFormTextField('email', {
      component: {
        type: 'email',
      },
      required: true,
      validation: [ValidationType.email],
    }),
    buildFormMultipleChoiceField('alreadyCustomer', {
      component: {
        options: [
          {
            icon: faThumbsUp,
            value: 'yes',
          },
          {
            icon: faThumbsDown,
            value: 'no',
          },
        ],
        columns: 2,
        singleAnswer: true,
      },
      required: true,
    }),
    buildFormCheckbox('privacyConfirmation', {
      component: {
        showLabel: true,
      },
      hideLabel: true,
      required: true,
      outputFormatter: () => ({
        formattedLabel: false,
        formattedValue: false,
      }),
    }),
  ],
  id: StepId.CONTACT_FORM,
  type: StepTypes.CONTACT_FORM,
};

export default config;

// Resettable is something that supports resetting its state
export interface Resettable {
  reset: () => void;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isResettable = (something: any): something is Resettable => {
  try {
    return typeof something.reset === 'function';
  } catch (error) {
    return false;
  }
};

/* eslint-disable sort-keys-fix/sort-keys-fix */
export default {
  start: {
    buttonText: 'Assistenten starten',
    description:
      'Sie planen Ihre Flotte zu elektrifizieren und Ihre Standorte mit Ladeinfrastruktur auszustatten? Wir entwickeln für Sie ein entsprechendes Standortkonzept. Mit unserem LIS-Standortassistenten helfen Sie uns, für Sie ein passendes Angebot zu erstellen!',
    title: 'LIS-Standortassistent',
    step: {
      label: 'Start',
    },
  },
  location: {
    title: 'Standort',
    step: {
      label: 'Standort',
    },
    label: 'An welchem Standort möchten Sie eine Ladeinfrastruktur errichten?',
    formFields: {
      lisArea: {
        options: {
          undergroundCarPark: {
            label: 'Tiefgarage',
          },
          outdoorArea: {
            label: 'Außenbereich',
          },
          both: {
            label: 'Beides',
          },
          other: {
            label: 'Andere',
          },
        },
        label:
          'In welchem Bereich soll die Ladeinfrastruktur aufgebaut werden?',
        formattedLabel: 'Zielbereich der Ladeinfrastruktur',
      },
      customLisArea: {
        label: 'Anderer Bereich:',
      },
      alreadyHasLis: {
        label:
          'An diesem Standort wird bereits eine Ladeinfrastruktur betrieben',
        formattedLabel: 'Ladeinfrastruktur bereits vorhanden',
        component: {
          label:
            'An diesem Standort wird bereits eine Ladeinfrastruktur betrieben',
        },
        yes: 'Ja',
        no: 'Nein',
      },
      chargingPoints: {
        label: 'Wie viele Ladepunkte sind geplant?',
        formattedLabel: 'Anzahl Ladepunkte',
        helperDialog: {
          text: 'Bitte geben Sie hier die Anzahl der Ladepunkte an, die Sie an dem Standort installieren möchten.',
        },
      },
    },
  },
  information: {
    title: 'Informationen',
    step: {
      label: 'Informationen',
    },
    formFields: {
      settleUser: {
        label: 'Sollen die Nutzer abgerechnet werden?',
        formattedLabel: 'Nutzer abrechnen',
        options: {
          yes: {
            label: 'Ja',
          },
          no: {
            label: 'Nein',
          },
        },
      },
      powerKW: {
        label: 'Wie hoch ist die Anschlussleistung Ihres Hausanschlusses?',
        formattedLabel: 'Anschlussleistung des Hausanschlusses',
        helperDialog: {
          text: 'Diese Angabe hilft uns bei der Planung, Sie können dieses Feld aber auch leer lassen, wenn Ihnen diese Information nicht vorliegt.',
        },
      },
      internetConnection: {
        label:
          'Steht eine Internetverbindung an dem geplanten Standort zur Verfügung?',
        formattedLabel: 'Verfügbare Internetverbindung am Standort',
        helperDialog: {
          text: 'Wir empfehlen in der Regel die Anbindung der Wallboxen über LTE. Um dies umzusetzen muss jedoch eine ausreichende Empfangsqualität am Standort gegeben sein.',
        },
        options: {
          lan: {
            label: 'LAN',
          },
          wlan: {
            label: 'WLAN',
          },
          lte: {
            label: 'LTE',
          },
        },
      },
      propertyOwner: {
        label: 'Sind Sie der Grundstückseigentümer?',
        formattedLabel: 'Grundstückseigentümer',
        options: {
          yes: {
            label: 'Ja',
          },
          no: {
            label: 'Nein',
          },
        },
      },
    },
  },
  documents: {
    title: 'Dokumente',
    step: {
      label: 'Dokumente',
    },
    formFields: {
      uploadDocuments: {
        label: 'Upload',
        htmlDescription:
          'Bitte laden Sie hier Pläne und / oder Fotos des Bereiches hoch, auf denen die gewünschte Position der Ladestationen erkennbar ist. Gerne können Sie uns auch unter "Bemerkungen" den Link zu Ihrem Standort auf <a class="gc-text-primary" href="https://www.google.de/maps" target="_blank">Google Maps</a> oder <a class="gc-text-primary" href="https://www.openstreetmap.de/karte" target="_blank">OpenStreetMap</a> schicken.',
        helperDialog: {
          title: 'Ein Beispiel könnte so aussehen:',
        },
      },
      additionalComments: {
        label: 'Bemerkungen',
        helperDialog: {
          text: 'Bitte teilen Sie uns weitere Informationen mit, die für Ihr Projekt relevant sein könnten.',
        },
      },
    },
  },
  wallboxes: {
    title: 'Wallboxen',
    step: {
      label: 'Wallboxen',
    },
    description:
      'Vielen Dank für Ihre Anfrage. Die für Sie am besten geeignete Wallbox werden wir gemeinsam mit Ihnen ermitteln. Hier können Sie sich jedoch schon einmal einen Überblick über mögliche Wallboxmodelle verschaffen.',
    loadingError: 'Produkte konnten nicht geladen werden.',
    loadingMessage: 'Produkte werden geladen …',
    noProductsFound:
      'Leider gibt es keine Produkte, die Ihren Kriterien entsprechen.',
    moreInformation: 'Weitere Informationen',
    modal: {
      button: {
        close: 'Schließen',
      },
      details: {
        general: {
          label: 'Allgemein',
          chargingPowerConfigurable: 'Ladeleistung konfigurierbar',
          electricCurrentConfigurable: 'Stromstärke konfigurierbar',
        },
      },
      extras: {
        label: 'Extras',
      },
      chargingPoints: {
        label: 'Ladepunkte',
      },
      dimensions: {
        label: 'Produktmaße',
        width: 'Breite',
        height: 'Höhe',
        length: 'Länge',
        weight: 'Gewicht',
      },
    },
  },
  consultation: {
    title: 'Beratung',
    step: {
      label: 'Beratung',
    },
    formFields: {
      consultationChoice: {
        label: 'Haben Sie Interesse an einer Beratung?',
        formattedLabel: 'Interesse an Beratung im Bereich',
        options: {
          energySupply: {
            label: 'Energiebezug',
          },
          energyEfficiency: {
            label: 'Energieeffizienzberatung',
          },
          photovoltaicStorage: {
            label: 'Photovoltaik/Speicher',
          },
          heat: {
            label: 'Wärme',
          },
        },
        no: 'Nein',
      },
    },
  },
  contactForm: {
    title: 'Ihre Kontaktdaten',
    step: {
      label: 'Kontaktformular',
    },
    formFields: {
      firstname: {
        label: 'Vorname',
      },
      lastname: {
        label: 'Nachname',
      },
      street: {
        label: 'Straße',
      },
      streetNumber: {
        label: 'Hausnr.',
      },
      zipcode: {
        label: 'PLZ',
      },
      city: {
        label: 'Stadt',
      },
      phone: {
        label: 'Telefonnummer',
      },
      email: {
        label: 'E-Mail-Adresse',
      },
      installAddressDiffers: {
        label:
          'Weichen Ihre Kontaktdaten von der Adresse des geplanten Standortes ab?',
        formattedLabel: 'Kontaktdaten abweichend vom Standort',
        options: {
          yes: {
            label: 'Ja',
          },
          no: {
            label: 'Nein',
          },
        },
      },
      installAddressLabel: {
        label:
          'Wenn Ihre obigen Kontaktdaten vom geplanten Standort abweichen, geben Sie bitte die Adresse des geplanten Ladeinfrastruktur-Projekets ein:',
      },
      installAddressStreet: {
        label: 'Straße',
      },
      installAddressStreetNumber: {
        label: 'Hausnr.',
      },
      installAddressZipcode: {
        label: 'PLZ',
      },
      installAddressCity: {
        label: 'Stadt',
      },
      privacyConfirmation: {
        component: {
          label:
            'Hiermit stimme ich der <a href="#" target="_blank">Datenschutzerklärung</a> zu.',
        },
      },
    },
  },
  done: {
    title: 'Fertig',
    step: {
      label: 'Fertig',
    },
  },
};
/* eslint-enable sort-keys-fix/sort-keys-fix */

<script>
import { VCardActions } from 'vuetify/lib';
export default VCardActions.extend({
  props: {
    elevation: {
      default: 0,
      type: [String, Number],
    },
  },
});
</script>
